import React from "react";
import {PuffLoader} from "react-spinners";



export default function Loader() {
    return(
        <div className="sweet-loading">
            <PuffLoader color={'#FF00FF'}/>
        </div>
    )
}
