import styled from 'styled-components';


export const FormSection = styled.div`
  /* color: #fff; */
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 500px;
  max-width: 500px;
  padding: 60px 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 1);
  @media screen and (max-width: 768px) {
    min-width: 100%;
    max-width: 100% !important;
  }
`;

export const FormTitle = styled.h1`
	margin-bottom: 10px;
    color: #fff;
	font-size: 32px;
	line-height: 1.1;
	font-weight: 600;
`;


export const FormColumn = styled.div`
	/* margin-bottom: 15px; */
	padding: 50px;
    color: #fff;
	border: 20px;
	flex: 1;
	max-width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
	}
	img {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;

export const FormRow = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 -15px -15px -15px;
	flex-wrap: wrap;
	align-items: center;
`;

export const FormWrapper = styled.form`
	/* max-width: 540px; */
	padding-top: 0;
	width: 100%;
`;
export const FormSpan = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  color: #333;
  font-size: clamp(0.6rem, 1.2vw, 0.7rem);
  line-height: 18px;
  padding: 10px;
  text-align:  justify;
  letter-spacing: 2px;
`;

export const FormText = styled.p`
  margin-bottom: 2px;
  color: #fff;
  font-size: clamp(0.8rem, 1.1vw, 0.8rem);
  line-height: 24px;
  padding: 10px;
  text-align: start;
  letter-spacing: 2px;
`;

export const FormLink = styled.a`
  margin-top: 15px;
  text-decoration: none;
  color: #ccc;
  font-size: clamp(0.6rem, 1.5vw, 0.7rem);
  line-height: 24px;
  padding: 10px;
  text-align: start;
  letter-spacing: 2px;
`;

export const FormButton = styled.button`
  border-radius: 4px;
  min-width: 100%;
  width: 100%
  position: relative;
  background-color: #FF00FF;
  white-space: nowrap;
  padding: 2px;
  font-size: 20px;
  color: black;
  outline: none;
  border: 2px solid #FF00FF;
  cursor: pointer;
  overflow: hidden;


  &:before {
    background: none;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 100%;
    height: 0%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover:before {
    height: 500%;
  }

  &:hover {
    color: #FE284A;
    transition: all .2s;
    animation: shadow .6s infinite linear;
    background-color: #FFF
  }

  @keyframes shadow {
    0% {
      box-shadow: 0 0 10px 0 #FE284A, 0 0 10px 0 #FE284A;
    }
    100% {
      box-shadow: 0 0 18px 6px rgba(143, 48, 205, 1), 0 0 4px 50px rgba(143, 48, 205, 1);
    }
  }
`;

