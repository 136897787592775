import React from 'react'

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./Pages/Home";
import Casino from "./Components/Casino/Casino";

export default function App() {
 return (
     <>
         <BrowserRouter>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/cat" element={<Casino link = 'https://catchthecatkz.com/dcb6190bb'/>}/>
            </Routes>
         </BrowserRouter>
     </>
 )
}
