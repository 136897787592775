import React from "react";
import Hero from "../Components/Hero/Hero";

export default function Home() {
    return (
        <>
            <Hero
                video = './assets/cat.mp4'
                btn = 'CAT CASINO'
            />
        </>
    )
}
