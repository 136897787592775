import styled, {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 960px) {
    padding: 0 30px;
  }
`;
export const NameButton = styled.h1`
  text-align: center;
  font-size: 34px;  
`

export const Button = styled.button`
  border-radius: 4px;
  min-width: 200px;
  position: absolute;
  background-color: #FF00FF;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 40px;
  color: black;
  outline: none;
  border: 2px solid #FF00FF;
  cursor: pointer;
  overflow: hidden;
  margin-top: 100%;


  &:before {
    background: none;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 100%;
    height: 0%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover:before {
    height: 500%;
  }

  &:hover {
    color: #FF00FF;
    transition: all .2s;
    animation: shadow .6s infinite linear;
    background-color: #FFF
  }

  @keyframes shadow {
    0% {
      box-shadow: 0 0 10px 0 ##FF00FF, 0 0 10px 0 #FF00FF;
    }
    100% {
      box-shadow: 0 0 18px 6px rgba(143, 48, 205, 1), 0 0 4px 50px rgba(143, 48, 205, 1);
    }
  }
`;


export default GlobalStyles
