import React from 'react';
import {
    FormColumn,
    FormWrapper,
    FormSection,
    FormRow,
    FormButton,
    FormTitle,
    FormText,
    FormSpan,
} from './FormStyles';
import {Link} from "react-router-dom";

const Form = () => {
    return (
        <FormSection>
                <FormRow>
                    <FormColumn small>
                        <FormTitle>Информация</FormTitle>
                        <FormWrapper>
                            <FormText>
                                Уважаемые игроки! <br/>
                                Welcome pack. <br/>
                                100% + 100 FS <br/>
                                Promo codes: <br/>
                                - SNO - 100FS no dep<br/>
                                - SAWAT - 200% dep 
                            </FormText>

                            <Link to='cat'>
                                <FormButton>
                                    ВОЙТИ
                                </FormButton>
                            </Link>
                            <FormSpan>
                                 #catcasino #cat_casino #кэт_казино #регистрация #online #зеркало #casino_cat #рабочее_зеркало #онлайн #играть #казино_кат #на_деньги #бесплатно #кэтказино #официальный_вход #зеркало_на_сегодня
                                 #бонус  #войти #вход #слоты #бездепозитный #скачать  #официальный #промокод #бонус_за_регистрацию  #актуальное_зеркало #free #официальный_сайт #сайт #мобильная #игровые_автоматы #bonus  #2024 #free #бездеп 
                            </FormSpan>
                        </FormWrapper>
                    </FormColumn>
                </FormRow>
        </FormSection>
    );
};

export default Form;
